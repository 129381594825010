<owa-layout [headerTitle]="'Notes'">
   <div class="datatable-top-filter-section">
      <div class="datatable-top-filter-section-left">
         <div
            class="filter-list-header"
            (click)="toggleFiltersPanel()"
         >
            <span>Filters</span>
            <i class="material-icons">tune</i>
         </div>
         <div class="datatable-breadcrumb-section">
            <div class="datatable-breadcrumb-filters">
               <ul
                  class="datatable-breadcrumb-applied-filters"
                  [hidden]="!appliedSideBarFilters || appliedSideBarFilters.length === 0"
               >
                  <li *ngFor="let filter of appliedSideBarFilters">
                     <span class="filter-category">{{ filter.Label }} {{ filter.shortOperationLabel() }}</span>
                     <span
                        class="filter-value"
                        *ngIf="$any(filter).Values.length"
                     >{{ filter.DisplayValue }}</span>
                     <span
                        class="material-icons remove-filter-icon"
                        (click)="onFilterRemoved(filter)"
                     >close</span>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   </div>

   <div class="datatable-section">
      <lcs-datatable-filters
         [hidden]="!showFilters"
         [dataTableFilters]="historyFilters"
         [filterOperations]="filterOperations"
         (clearFilters)="onClearFilters()"
         (filtersApplied)="onFiltersApplied($event)"
         (toggleSection)="toggleFiltersPanel()"
      ></lcs-datatable-filters>

      <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
      <div
         class="datatable-count"
         *ngIf="$any((results | async)) > 0"
      >
         {{ results | async }} of {{ totalResults | async }} Notes
      </div>

      <lcs-loading-overlay [show]="$any(isDataLoading | async)">
         <div
            class="table-wrapper"
            lcsInfiniteScroll
            [lcsInfiniteScrollThreshold]="threshold"
            (infiniteScroll)="onInfiniteScroll($event)"
         >
            <lcs-table [dataSource]="$any(observableHistory | async)">
               <tr
                  lcs-header-row
                  *lcsHeaderRowDef="columns; sticky: false"
               ></tr>
               <tr
                  lcs-row
                  *lcsRowDef="let row; columns: columns"
               ></tr>

               <ng-container
                  lcsColumnDef="HistoryDate"
                  [width]="9"
                  [priority]="1"
               >
                  <th
                     class="small-date"
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Date</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >
                     <div class="tableCell"><img
                           *ngIf="row.IsPinned && !hasThemePinnedNotes; else themePinned"
                           class="pin-icon"
                           src="/assets/images/pin.png"
                        />
                     </div>
                     <ng-template #themePinned>
                        <img
                           *ngIf="row.IsPinned"
                           class="pin-icon"
                           src="/assets/images/pin-theme.png"
                        />
                     </ng-template>
                     <div
                        *ngIf="!row.IsPinned && hasPinnedNotes"
                        class="pin-icon alignLineWithPinIcon"
                     ></div>
                     <div class="tableCell">{{ row.HistoryDate | date: "MM/dd/yyyy" }}</div>
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="Note"
                  [width]="30"
                  [priority]="2"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Note</th>
                  <td
                     class="history-note"
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                  >
                     {{ row.Note }}
                  </td>
               </ng-container>

               <ng-container
                  lcsColumnDef="File"
                  [width]="8"
                  [priority]="3"
               >
                  <th
                     lcs-header-cell
                     *lcsHeaderCellDef
                  >Files</th>
                  <td
                     lcs-cell
                     *lcsCellDef="let row; let index = index"
                     class="attachment"
                  >
                     <div
                        *ngIf="row.HasFile"
                        style="display:flex;"
                     >
                        <div
                           *ngIf="row.Files.length === 1"
                           style="display:flex;"
                        >
                           <div
                              *ngFor="let file of row.Files"
                              style="display:flex;"
                           >
                              <a
                                 href="{{ file.DownloadURL }}"
                                 target="_blank"
                              >
                                 <i class="material-icons">save_alt</i>
                              </a>
                           </div>
                        </div>
                        <div
                           *ngIf="row.Files.length > 1"
                           class="imageContainer"
                           style="display:flex;"
                        >
                           <a
                              (click)="openCarousel(row.HistoryID, row.Note)"
                              target="_blank"
                              style="cursor: pointer;"
                           >
                              <i
                                 class="far fa-images"
                                 style="margin-left: 3px;"
                              ></i>
                           </a>
                        </div>
                     </div>
                  </td>
               </ng-container>
            </lcs-table>

            <!-- $any() - (error TS2531) FIXME: Object is possibly 'null'. -->
            <div
               class="datatable-internal-spinner-wrapper"
               *ngIf="!(isDataLoading | async) && $any((observableHistory | async))?.length < $any((totalResults | async))"
            >
               <lcs-loading-overlay [show]="true"></lcs-loading-overlay>
            </div>
         </div>
      </lcs-loading-overlay>
   </div>
</owa-layout>