<owa-dashboard-tile
   [title]="'Notes'"
   [notificationCount]="notificationCount"
   [isViewMore]="true"
   [viewMoreComponent]="'historynotes'"
>
   <div *ngIf="observableHistory != null; else noNotes">
      <lcs-table [dataSource]="$any(observableHistory | async)">
         <tr
            lcs-header-row
            *lcsHeaderRowDef="columns; sticky: false"
         ></tr>
         <tr
            lcs-row
            *lcsRowDef="let row; columns: columns"
         ></tr>

         <ng-container
            lcsColumnDef="HistoryDate"
            [width]="9"
            [priority]="1"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Date</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
            >
               <div class="tableCell"><img
                     *ngIf="row.IsPinned && !hasThemePinnedNotes; else themePinned"
                     class="pin-icon"
                     src="/assets/images/pin.png"
                  />
               </div>
               <ng-template #themePinned>
                  <img
                     *ngIf="row.IsPinned"
                     class="pin-icon"
                     src="/assets/images/pin-theme.png"
                  />
               </ng-template>
               <div
                  *ngIf="!row.IsPinned && hasPinnedNotes"
                  class="pin-icon alignLineWithPinIcon"
               ></div>
               <div class="tableCell">{{ row.HistoryDate | date: "MM/dd/yyyy" }}</div>
            </td>
         </ng-container>

         <ng-container
            lcsColumnDef="Note"
            [width]="20"
            [priority]="2"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Note</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
            >{{ row.Note }}</td>
         </ng-container>

         <ng-container
            lcsColumnDef="File"
            [width]="3"
            [priority]="3"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            >Files</th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
               class="attachment"
            >
               <div *ngIf="row.HasFile">
                  <div *ngIf="row.Files.length === 1">
                     <div *ngFor="let file of row.Files">
                        <a
                           href="{{ file.DownloadURL }}"
                           target="_blank"
                        >
                           <i class="material-icons">save_alt</i>
                        </a>
                     </div>
                  </div>
                  <div *ngIf="row.Files.length > 1">
                     <a
                        (click)="openCarousel(row.HistoryID, row.Note)"
                        target="_blank"
                        style="cursor: pointer;"
                     >
                        <i class="far fa-images"></i>
                     </a>
                  </div>
               </div>
            </td>
         </ng-container>

         <ng-container
            lcsColumnDef="NewRow"
            [width]="1"
            [priority]="4"
         >
            <th
               lcs-header-cell
               *lcsHeaderCellDef
            ></th>
            <td
               lcs-cell
               *lcsCellDef="let row; let index = index"
            >
               <div *ngIf="row.IsNew">
                  <span class="notification-icon"></span>
               </div>
            </td>
         </ng-container>
      </lcs-table>
   </div>
   <ng-template #noNotes>
      <div class="no-result">No History/Notes found</div>
   </ng-template>
</owa-dashboard-tile>