export class WebChatOptions {
   RMURL: string;
   ENTID: string;
   Location: string;
   QueueName: string;
   InitialName: string;
   InitialEmailAddress: string;
   InitialPropertyName: string;
   InitialAccountNumber: number;
   WebChatStylingURL: string;
   WebChatScriptURL: string;
   WebChatNotificationURL: string;
   IsWebChatEnabled: boolean;
}
